import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { AuthProvider } from "./Contexts/AuthContext";
import { ArticlesProvider } from "./Contexts/ArticlesContext";

import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  <AuthProvider>
    <ArticlesProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ArticlesProvider>
  </AuthProvider>,
  document.getElementById("root")
);
