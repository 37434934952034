import React from "react";
import { useArticles } from "../../Contexts/ArticlesContext";
import parse from "html-react-parser";
import { convertEpochToDate, getArticle } from "../../Utils/articlesHelper";

import classes from "./Article.module.scss";

const Article = (props) => {
  const blog = useArticles();
  const id = props.id;

  const article = getArticle(blog.blogCollection, id);

  /**
   * Only load if article is ready.
   *
   * This solves the page reload problem. On page reload, getArticle() finishes
   * fetching before ArticlesContext - getBlogCollection() completes.
   */
  if (article) {
    const title = article.title;
    const creationDate = convertEpochToDate(article.creationDate);
    const content = article.content;

    return (
      <div>
        <div className={classes.title}>{title}</div>
        <div className={classes.date}>{creationDate}</div>
        <div className={classes.content}>{parse(content)}</div>
      </div>
    );
  }

  return <></>;
};

export default Article;
