import React, { useState, useEffect, useContext, createContext } from "react";
import firebaseAPI from "../firebase";

// AuthContext contains a provider that is used in the return of the ...
// ... AuthProvider function.
const AuthContext = createContext();

// Hook for child components to get the auth object.
// This rerenders everytime the AuthContext changes.
export const useAuth = () => {
  return useContext(AuthContext);
};

// Hook: Provider
// This creates the auth object and handles state.
export const AuthProvider = ({ children }) => {
  const firebaseAuth = firebaseAPI.auth();

  const [user, setUser] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [signInError, setSignInError] = useState("");

  const userSignedIn = !!user;

  const signIn = async (email, password) => {
    return await firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        setUser(response.user);
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/user-not-found":
            setSignInError("Couldn't find your account");
            break;
          case "auth/invalid-email":
            setSignInError("Invalid. Please try again");
            break;
          case "auth/wrong-password":
            setSignInError("Incorrect password");
            break;
          default:
            setSignInError(error.code);
        }
      });
  };

  const signOut = async () => {
    return await firebaseAuth.signOut().then(() => {
      localStorage.setItem("pjel_isLoggedIn", false);
      setUser(false);
    });
  };

  // Subscribe to user upon mounting.
  // Because this sets the user and isAuthenticating state in the callback, ...
  // ... it will cause any component that utilizes this hook to re-render with ...
  // ... the latest auth object.
  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      // This occurs as a callback after the user has authenticated.
      setUser(user);
      setIsAuthenticating(false);
    });

    return () => {
      // Unsubscribe user upon unmounting. We can unsibscribe now because we ...
      // ... have captured all the data we need.
      unsubscribe();
    };
  }, [firebaseAuth]);

  // The user object and auth methods.
  const values = {
    user,
    isAuthenticating,
    signIn,
    signOut,
    isSignedIn: userSignedIn,
    signInError,
    setSignInError,
  };

  // Provider component that wraps the app and makes the auth object available ...
  // ... to any child component that calls useAuth().
  return (
    <AuthContext.Provider value={values}>
      {!isAuthenticating && children}
    </AuthContext.Provider>
  );
};
