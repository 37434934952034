import React from "react";
import { NavLink } from "react-router-dom";
import Search from "../../Pages/Search/Search";
import Settings from "../../Pages/Settings/Settings";
import SignOut from "../SignOut/SignOut";

import classes from "./NavBar.module.scss";

const NavBar = () => {
  return (
    <div className={classes.bodyNavBar}>
      <div className={classes.upperNavBar}>
        {/* Left aligned items */}
        <div className={classes.floatLeftItems}>
          {/* Title */}
          <div className={classes.titleNavBar}>Peace, Joy, Eternal Life</div>
        </div>
      </div>

      <div className={classes.lowerNavBar}>
        {/* Left aligned items */}
        <div className={classes.floatLeftItems}>
          <div className={classes.navDisplay}>
            <NavLink to="/home" activeClassName={classes.active}>
              Home
            </NavLink>
            <NavLink to="/general" activeClassName={classes.active}>
              General
            </NavLink>
            <NavLink to="/recipes" activeClassName={classes.active}>
              Recipes
            </NavLink>
            <NavLink to="/lds" activeClassName={classes.active}>
              LDS
            </NavLink>
            <NavLink to="/christian" activeClassName={classes.active}>
              Christian
            </NavLink>
            <NavLink to="/health" activeClassName={classes.active}>
              Health
            </NavLink>
            <NavLink to="/self-care" activeClassName={classes.active}>
              Self-care
            </NavLink>
            <NavLink to="/poems" activeClassName={classes.active}>
              Poems
            </NavLink>
          </div>
        </div>
        {/* Right aligned items */}
        <span className={classes.floatRightItems}>
          <Search />
          <Settings />
          <SignOut />
        </span>
      </div>
    </div>
  );
};

export default NavBar;
