import React from "react";
import { FiSettings } from "react-icons/fi";

import classes from "./Settings.module.scss";

const Settings = () => {
  return (
    <div className={classes.menuItem}>
      <FiSettings />
    </div>
  );
};

export default Settings;
