import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import firebaseConfig from "./localConf";

const firebaseAPI = firebase.initializeApp(firebaseConfig);

export default firebaseAPI;
export const db = firebase.firestore();
