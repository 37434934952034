import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { BiLogOut } from "react-icons/bi";

import { useAuth } from "../../Contexts/AuthContext";

import classes from "./SignOut.module.scss";

const SignOut = () => {
  const auth = useAuth();
  const history = useHistory();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const toggleConfirmation = () => {
    setShowConfirmation(!showConfirmation);
  };

  const handleLogout = async () => {
    await auth.signOut();
    history.replace(`/login`);
  };

  return (
    <div
      onClick={toggleConfirmation}
      className={showConfirmation ? classes.signOutActive : classes.signOut}
    >
      <BiLogOut />
      {showConfirmation && (
        <CSSTransition
          in={true}
          appear={true}
          timeout={400}
          classNames={{
            appear: classes["fade-appear"],
            appearActive: classes["fade-appear-active"],
          }}
        >
          <div className={classes.signOutConfirmation}>
            Are you sure?
            <div className={classes.yes} onClick={handleLogout}>
              Yes
            </div>
            <div className={classes.cancel} onClick={toggleConfirmation}>
              Cancel
            </div>
          </div>
        </CSSTransition>
      )}
    </div>
  );
};

export default SignOut;
