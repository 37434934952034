import React, { createContext, useContext, useState, useEffect } from "react";

import { db } from "../firebase";

const ArticlesContext = createContext();

export const useArticles = () => {
  return useContext(ArticlesContext);
};

export const ArticlesProvider = ({ children }) => {
  const collection = [];
  const [blogCollection, setBlogCollection] = useState([]);

  /**
   * getBlogCollection - Get blog collection from Firebase.
   * Initiated once, when the app is loaded / reloaded.
   */
  const getBlogCollection = async () => {
    const blogCollectionRef = db.collection("blog");
    const snapshot = await blogCollectionRef.get();

    snapshot.forEach((doc) => {
      collection.push({
        id: doc.id,
        title: doc.data().title,
        category: doc.data().category,
        content: doc.data().content,
        creationDate: doc.data().creationDate,
      });
    });

    setBlogCollection(collection);
    console.log("ArticlesContext - Completed fetching blog collection.");
  };

  /**
   * addEntry - Add entry to database.
   *
   * @param {String} title - title of entry.
   * @param {String} category - category of entry.
   * @param {String} content - entry contents.
   */
  const addEntry = async (title, category, content) => {
    const date = new Date();
    const blogCollectionRef = db.collection("blog");
    await blogCollectionRef.add({
      title: title,
      creationDate: date,
      category: category,
      content: content,
    });
  };

  /**
   * editEntry - Edit entry in database.
   *
   * @param {string} documentId - currrent document being edited.
   * @param {String} title - title of entry.
   * @param {String} category - category of entry.
   * @param {String} content - entry contents.
   */
  const editEntry = async (documentId, title, category, content) => {
    const blogCollectionRef = db.collection("blog").doc(documentId);
    await blogCollectionRef.set({
      title: title,
      category: category,
      content: content,
    });
  };

  /**
   * deleteEntry - Delete entry in database.
   *
   * @param {string} documentId - document wanting to be deleted.
   */
  const deleteEntry = async (documentId) => {
    await db.collection("blog").doc(documentId).delete();
  };

  /**
   * getCategoryTitles - get all titles belonging to the category being queried
   *
   * @param {String} category - category being queried
   * @return {array} array of titles belonging to the category
   */
  const getCategoryTitles = (category) => {
    let titles = [];
    blogCollection.forEach((blog) => {
      if (blog.category === category) {
        titles.push({
          id: blog.id,
          title: blog.title,
          creationDate: blog.creationDate,
        });
      }
    });
    return titles;
  };

  useEffect(() => {
    getBlogCollection();
    // eslint-disable-next-line
  }, []);

  const values = {
    getBlogCollection,
    blogCollection,
    addEntry,
    editEntry,
    deleteEntry,
    getCategoryTitles,
  };

  return (
    <ArticlesContext.Provider value={values}>
      {children}
    </ArticlesContext.Provider>
  );
};
