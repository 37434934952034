import React from "react";
import { CgSearch } from "react-icons/cg";

import classes from "./Search.module.scss";

const Search = () => {
  return (
    <div className={classes.menuItem}>
      <CgSearch />
    </div>
  );
};

export default Search;
