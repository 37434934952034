import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { useAuth } from "./Contexts/AuthContext";

import classes from "./App.module.scss";
import SignIn from "./Pages/SignIn/SignIn";
import NavBar from "./Widgets/NavBar/NavBar";
import { ArticlesProvider } from "./Contexts/ArticlesContext";
import Welcome from "./Pages/Welcome/Welcome";
import BlogBody from "./Pages/BlogBody/BlogBody";
import AddEntry from "./Pages/AddEntry/AddEntry";

function App() {
  const auth = useAuth();
  const userSignedInState = auth.isSignedIn;

  // If user is signed in:
  if (userSignedInState) {
    return (
      <ArticlesProvider>
        <NavBar />
        <div className={classes.blogBody}>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/home" />
            </Route>
            <Route path="/home" component={Welcome} />
            <Route
              path={["/addEntry"]}
              render={(props) => <AddEntry {...props} />}
            />
            <Route
              path={["/general/:id", "/general"]}
              render={(props) => <BlogBody {...props} category="general" />}
            />
            <Route
              path={["/recipes/:id", "/recipes"]}
              render={(props) => <BlogBody {...props} category="recipes" />}
            />
            <Route
              path={["/lds/:id", "/lds"]}
              render={(props) => <BlogBody {...props} category="lds" />}
            />
            <Route
              path={["/christian/:id", "/christian"]}
              render={(props) => <BlogBody {...props} category="christian" />}
            />
            <Route
              path={["/health/:id", "/health"]}
              render={(props) => <BlogBody {...props} category="health" />}
            />
            <Route
              path={["/self-care/:id", "/self-care"]}
              render={(props) => <BlogBody {...props} category="self-care" />}
            />
            <Route
              path={["/poems/:id", "/poems"]}
              render={(props) => <BlogBody {...props} category="poems" />}
            />
            <Route path="*">
              <Redirect to="/home" />
            </Route>
          </Switch>
        </div>
      </ArticlesProvider>
    );
  }

  // If user is not signed in:
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/login" />
      </Route>
      <Route path="/login">
        <div className={classes.bodyLogin}>
          <SignIn />
        </div>
      </Route>
      <Route path="*" exact>
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
}

export default App;
