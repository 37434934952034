import React from "react";
import { Link, useParams } from "react-router-dom";
import { useArticles } from "../../Contexts/ArticlesContext";
import { convertEpochToDate } from "../../Utils/articlesHelper";
import Tools from "../../Widgets/Tools/Tools";
import Article from "../Article/Article";

import classes from "./BlogBody.module.scss";

const BlogBody = (props) => {
  const { id } = useParams();
  const blog = useArticles();
  const categoryTitles = blog.getCategoryTitles(props.category);

  const categoryCollection = (
    <table>
      <tr>
        <th className={classes.headerTitle}>&nbsp;&nbsp;Title</th>
        <th className={classes.headerCreatedDate}>&nbsp;&nbsp;Created</th>
      </tr>
      {categoryTitles.map((categoryTitle) => (
        <tr key={categoryTitle.id}>
          <td>
            <Link
              to={`/${props.category}/${categoryTitle.id}`}
              className={classes.tableEntry}
            >
              {categoryTitle.title}
            </Link>
          </td>
          <td>{convertEpochToDate(categoryTitle.creationDate)}</td>
        </tr>
      ))}
    </table>
  );

  const article = <Article id={id} />;

  if (id) {
    return (
      <div className={classes.blogBody}>
        <div className={classes.blogContainer}>{article}</div>
        <Tools category={props.category} />
      </div>
    );
  }

  return (
    <div className={classes.blogBody}>
      <div className={classes.tableContainer}>{categoryCollection}</div>
      <Tools category={props.category} />
    </div>
  );
};

export default BlogBody;
