import React from "react";
import Tools from "../../Widgets/Tools/Tools";
import { CSSTransition } from "react-transition-group";

import classes from "./Welcome.module.scss";

const Welcome = () => {
  return (
    <div className={classes.welcomeContainer}>
      <div className={classes.welcome}>
        <div className={classes.title}>
          <CSSTransition
            in={true}
            appear={true}
            timeout={200}
            classNames={{
              appear: classes["fade__title-appear"],
              appearActive: classes["fade__title-appear-active"],
            }}
          >
            <div>Welcome!</div>
          </CSSTransition>
        </div>

        <div className={classes.content}>
          <CSSTransition
            in={true}
            appear={true}
            timeout={200}
            classNames={{
              appear: classes["fade__message-appear"],
              appearActive: classes["fade__message-appear-active"],
            }}
          >
            <div>Please select a category from the above navigation bar...</div>
          </CSSTransition>
        </div>
      </div>
      <Tools />
    </div>
  );
};

export default Welcome;
