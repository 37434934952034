import React, { useState } from "react";
import { useAuth } from "../../Contexts/AuthContext";

import classes from "./SignIn.module.scss";

const SignIn = () => {
  const auth = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const emailInputHandler = (event) => {
    setEmail(event.target.value);
  };

  const passwordInputHandler = (event) => {
    setPassword(event.target.value);
  };

  const signInHandler = async () => {
    await auth.signIn(email, password);
  };

  return (
    <div className={classes.containerSignIn}>
      <div className={classes.upperNavBar}>
        {/* Title */}
        <div className={classes.titleNavBar}>Peace, Joy, Eternal Life</div>
      </div>
      <div className={classes.signInForm}>
        {/* Input - Email */}
        <input
          className={classes.inputs}
          placeholder="Email"
          type="email"
          onChange={emailInputHandler}
          required
        />

        {/* Input - Password */}
        <input
          className={classes.inputs}
          placeholder="Password"
          type="password"
          onChange={passwordInputHandler}
          required
        />

        {/* Button - Authenticate */}
        <button onClick={signInHandler} className={classes.buttonSignIn}>
          Sign In
        </button>

        {/* Sign In error messages */}
        <div className={classes.signInErrorMessage}>{auth.signInError}</div>
      </div>
    </div>
  );
};

export default SignIn;
