/**
 * converEpochToDate
 *
 * Takes in the creationDate and converts it to human readable format.
 * @return {String} Day (number) Month (letters), Year
 */
const convertEpochToDate = (creationDate) => {
  const epoch = parseInt(creationDate.seconds);
  const dateFull = new Date(epoch * 1000);

  const year = dateFull.getFullYear();
  const day = dateFull.getDate();
  let month = dateFull.getMonth();

  switch (month) {
    case 1:
      month = "Jan";
      break;
    case 2:
      month = "Feb";
      break;
    case 3:
      month = "Mar";
      break;
    case 4:
      month = "Apr";
      break;
    case 5:
      month = "May";
      break;
    case 6:
      month = "June";
      break;
    case 7:
      month = "July";
      break;
    case 8:
      month = "Aug";
      break;
    case 9:
      month = "Sept";
      break;
    case 10:
      month = "Oct";
      break;
    case 11:
      month = "Nov";
      break;
    case 12:
      month = "Dec";
      break;
    default:
      month = "unknown";
      break;
  }

  const date = day + " " + month + ", " + year;
  return date;
};

/**
 * getArticle
 *
 * @return {object} article for the particular id.
 */
const getArticle = (blog, id) => {
  let currentArticle = blog.find((article) => {
    return id === article.id;
  });

  return currentArticle;
};

/**
 * description
 */
const validationCheckBeforePosting = (text, category, title) => {
  let error = "";

  if (text === "" && title === "" && category === "") {
    error = "*Please enter a title, select a category, and add some content";
  } else if (text === "" && title === "") {
    error = "*Please enter a title and add some content";
  } else if (title === "" && category === "") {
    error = "*Please enter a title and select a category";
  } else if (text === "" && category === "") {
    error = "*Please select a categoryand add some content";
  } else if (text === "") {
    error = "*Please enter some content";
  } else if (title === "") {
    error = "*Please enter a title";
  } else if (category === "") {
    error = "*Please select a category";
  }

  return error;
};

export { convertEpochToDate, getArticle, validationCheckBeforePosting };
