import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useArticles } from "../../Contexts/ArticlesContext";
import { validationCheckBeforePosting } from "../../Utils/articlesHelper";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";

import { CSSTransition } from "react-transition-group";
import classes from "./AddEntry.module.scss";

const AddEntry = (props) => {
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [error, setError] = useState();
  const [preview, setPreview] = useState(false);

  const history = useHistory();
  const blog = useArticles();

  const getTitle = (event) => {
    setTitle(event.target.value);
  };

  const getCategory = (event) => {
    setCategory(event.target.value);
  };

  const post = async () => {
    setError("");
    setError(validationCheckBeforePosting(text, category, title));
    if (error === "") {
      let categoryToDB = category.toLowerCase();
      await blog.addEntry(title, categoryToDB, text);
      await blog.getBlogCollection();

      setText("");
      setPreview(false);
      history.push(`/home/${category}`);
    }
  };

  const openPreview = () => {
    setPreview(true);
  };

  const closePreview = () => {
    setPreview(false);
  };

  const discard = () => {
    setTitle("");
    setText("");
    setCategory("");
    setPreview(false);
    setError("");

    history.goBack();
  };

  return (
    <CSSTransition
      in={true}
      appear={true}
      timeout={400}
      unmountOnExit={true}
      classNames={{
        appear: classes["fade-appear"],
        appearActive: classes["fade-appear-active"],
      }}
    >
      <div className={classes.container}>
        {preview === false && (
          <>
            <div>
              <div className={classes.setTitle}>Title</div>
              <input
                type="text"
                className={classes.titleInput}
                placeholder="Enter title here..."
                onChange={getTitle}
                value={title}
              />
            </div>

            <div className={classes.setCategoryContainer}>
              <div className={classes.setCategoryTitle}>Category</div>
              <div className={classes.setCategory}>
                <div>
                  <input
                    type="radio"
                    value="General"
                    name="category"
                    onChange={getCategory}
                  />
                  <label for="General">&nbsp;General</label>
                </div>
                <div>
                  <input
                    type="radio"
                    value="Recipes"
                    name="category"
                    onChange={getCategory}
                  />
                  <label for="Recipes">&nbsp;Recipes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    value="LDS"
                    name="category"
                    onChange={getCategory}
                  />
                  <label for="LDS">&nbsp;LDS</label>
                </div>
                <div>
                  <input
                    type="radio"
                    value="Christian"
                    name="category"
                    onChange={getCategory}
                  />
                  <label for="Christian">&nbsp;Christian</label>
                </div>
                <div>
                  <input
                    type="radio"
                    value="Health"
                    name="category"
                    onChange={getCategory}
                  />
                  <label for="Health">&nbsp;Health</label>
                </div>
                <div>
                  <input
                    type="radio"
                    value="Self-care"
                    name="category"
                    onChange={getCategory}
                  />
                  <label for="Self-care">&nbsp;Self-care</label>
                </div>
                <div>
                  <input
                    type="radio"
                    value="Poems"
                    name="category"
                    onChange={getCategory}
                  />
                  <label for="Poems">&nbsp;Poems</label>
                </div>
              </div>
            </div>
          </>
        )}

        <div className={classes.editorPreviewContainer}>
          {preview === false && (
            <CKEditor
              editor={ClassicEditor}
              data={text}
              onChange={(event, editor) => {
                const data = editor.getData();
                setText(data);
              }}
            />
          )}

          {preview === true && (
            <div className={classes.preview}>
              <div className={classes.previewTitle}>{title}</div>
              <div className={classes.previewContent}>{parse(text)}</div>
            </div>
          )}

          <div className={classes.buttons}>
            <button onClick={discard} className={classes.discardButton}>
              Discard
            </button>

            {error !== "" && <span className={classes.error}>{error}</span>}

            <span className={classes.bottomRightButtons}>
              {!preview && (
                <button onClick={openPreview} className={classes.previewButton}>
                  Preview
                </button>
              )}
              {preview && (
                <button
                  onClick={closePreview}
                  className={classes.previewButton}
                >
                  Back to editor
                </button>
              )}

              <button onClick={post} className={classes.postButton}>
                Post
              </button>
            </span>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default AddEntry;
