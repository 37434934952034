import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { BsPencil } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";

import classes from "./Tools.module.scss";

const Tools = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const addHandler = () => {
    history.push(`/addEntry`);
  };

  return (
    <div className={classes.toolsContainer}>
      <div className={classes.toolsTitle}>Tools</div>
      <div>
        <div className={classes.toolsItemContainer} onClick={addHandler}>
          <IoMdAdd className={classes.icon} />
          &nbsp; <span className={classes.text}>Add</span>
        </div>
        {id && (
          <>
            <div className={classes.toolsItemContainer}>
              <BsPencil className={classes.icon} />
              &nbsp; <span className={classes.text}>Edit</span>
            </div>
            <div className={classes.toolsItemContainer__delete}>
              <RiDeleteBinLine className={classes.icon} />
              &nbsp; <span className={classes.text}>Delete</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Tools;
